import React, { useState } from 'react'
import VizSensor from 'react-visibility-sensor'
import classnames from 'classnames'

// Components
import Pic from '../images/pic'

//CSS
import componentStyle from './comments.module.css'

const Comments = () => {
  const [isVisible, setVisibility] = useState(false)

  let baseClass = classnames(
    'grid',
    'grid-cols-12',
    { 'opacity-0': !isVisible },
    { 'text-slide-in': isVisible }
  )
  const {
    commentCard,
    customerComment,
    customerName,
    customerPic,
    customerPlan,
    commentDate,
  } = componentStyle

  const card = classnames(
    commentCard,
    'lg:col-span-4',
    'col-span-12',
    'lg:mb-0',
    'mb-20'
  )

  return (
    <section id="comments" className="h-full">
      <VizSensor
        onChange={setVisibility}
        active={!isVisible}
        partialVisibility={true}
      >
        <div className={baseClass}>
          <div className="col-start-2 lg:col-start-3 col-span-10 my-12">
            <h2 className="font-medium text-left text-gray-800 text-4xl lg:text-4xl">
              ¿Qué dicen los clientes de Alex?
            </h2>
          </div>
          <div className="col-start-2 lg:col-start-3 lg:col-span-8 col-span-10 mt-8">
            <div className="grid grid-cols-12 gap-6">
              <div className={card}>
                <div className={customerPic}>
                  <Pic filename="home/Oriana.JPG" />
                </div>
                <p className={commentDate}> 12/06/2021</p>
                <h4 className={customerName}>Oriana (34)</h4>
                <p className={customerComment}>
                  "Ya tengo contratada una póliza de salud con Redbridge y me ha
                  ido excelente. Alex es el plan perfecto como complemento
                  porque puedo sentirme tranquila. Recomendado."
                </p>
                <p className={customerPlan}> Alex Full</p>
              </div>
              <div className={card}>
                <div className={customerPic}>
                  <Pic filename="home/FofoStory.png" />
                </div>
                <p className={commentDate}> 23/05/2021</p>
                <h4 className={customerName}>Andres (36)</h4>
                <p className={customerComment}>
                  "Yo viajo regularmente y con Alex siento que tengo excelente
                  protección, además cumple con los requisitos de coberturas
                  médicas que exigen muchos países para la entrada."
                </p>
                <p className={customerPlan}> Alex Full</p>
              </div>
              <div className={card}>
                <div className={customerPic}>
                  <Pic filename="home/Johana1.JPG" />
                </div>
                <p className={commentDate}> 18/04/2021</p>
                <h4 className={customerName}>Johvana (41)</h4>
                <p className={customerComment}>
                  "Como madre soltera me quita un peso de encima saber que el
                  futuro de mis hijos está protegido en caso de que no pueda
                  seguir cuidándoles, además muy fácil y rápido de contratar"
                </p>
                <p className={customerPlan}> Alex Prime</p>
              </div>
            </div>
          </div>
        </div>
      </VizSensor>
    </section>
  )
}

export default Comments
