import React, { useEffect, useState } from 'react'
import { globalHistory } from '@reach/router'

// Plugins
import scrollTo from 'gatsby-plugin-smoothscroll'

// Components
import Layout from '../components/layout/layout'
import Hero from '../components/hero/hero'
import WhyPedro from '../components/why-pedro/component'
import GrayZone from '../components/gray-zone/component'
import HomePlans from '../components/home-plans/component'
import Referrals from '../components/referrals-zone/component'
import Video from '../components/video'
import SEO from '../components/seo'
import Comments from '../components/comments/component'

// Apollo
import { useQuery, gql } from '@apollo/client'

const IndexPage = () => {
  const [isAwaken, setAwakening] = useState(false)
  const PING = gql`
    query awakeServer {
      ping
    }
  `
  const { data } = useQuery(PING, { skip: !!isAwaken })
  useEffect(() => {
    if (
      globalHistory.location &&
      globalHistory.location.state &&
      globalHistory.location.state.scroll
    ) {
      scrollTo(`#${globalHistory.location.state.scroll}`)
      globalHistory.location.state = {}
    }
    if (data) {
      setAwakening(true)
    }
  }, [data])
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[
          `seguro de vida`,
          `seguro de viaje`,
          `insurtech`,
          `seguro funerario`,
          `seguro invalidez`,
          `seguro accidentes`,
          `seguros de vida`,
        ]}
      />
      <Hero />
      <WhyPedro />
      <HomePlans />
      <GrayZone />
      <Video
        videoSrcURL="https://www.youtube.com/embed/GsBtEgZU-aY?autoplay=0&rel=0&showinfo=0"
        videoTitle="Alex Seguro Explained"
      />
      <Comments />
      <Referrals />
    </Layout>
  )
}

export default IndexPage
