import React from 'react'
import { navigate } from 'gatsby'
import { Parallax, Background } from 'react-parallax'

//Components
import Pic from '../images/pic'

const HeroText = () => (
  <section
    id="top"
    className="flex align-center lg:w-screen mt-20 lg:mt-4 w-full h-full"
  >
    <Parallax strength={-200} blur={{ min: 0, max: 50 }}>
      <div className="text-slide-in z-10 w-11/12 mx-auto">
        <h1 className="p-1 mt-3 lg:p-0 text-center text-gray-800 text-4xl lg:text-5xl font-bold">
          Tranquilidad para ti y tu familia.
        </h1>
        {/* <h2 className="p-0 text-center text-gray-600 text-2xl lg:text-3xl font-medium">
          En 5 minutos. Sin exámenes médicos. Sin papeleos. <br/>
        </h2> */}
        <h4 className="text-center text-gray-600 md:text-md mt-4 lg:text-xl font-light">
          Accidentes | Viajes | Vida | Funeral | Repatriación
        </h4>
        <div className="flex flex-col lg:w-2/6 w-full mx-auto justify-center">
          <button
            onClick={() => {
              navigate('/quote')
            }}
            className="text-center default-button py-4 tracking-widest px-6 lg:px-8 font-bold text-lg lg:text-xl"
          >
            Cotizar desde $11,50/mes
          </button>
          <div className="flex justify-end mt-1 lg:mt-4">
            <p className="mt-1">por</p>
            <div className="w-40 h-5">
              <Pic filename="logo/redbridge-new.png" />
            </div>
          </div>
        </div>
      </div>
      <Background>
        <div className="mt-56 p-10">
          <Pic filename="home/travel.png" />
        </div>
      </Background>
    </Parallax>
  </section>
)

export default HeroText
