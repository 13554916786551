import React, { useState } from 'react'
import VizSensor from 'react-visibility-sensor'
import classnames from 'classnames'

//Components
import Pic from '../images/pic'

const GrayZone = () => {
  const [isVisible, setVisibility] = useState(false)
  let baseClass = classnames(
    'grid',
    'grid-cols-12',
    { 'opacity-0': !isVisible },
    { 'text-slide-in': isVisible }
  )

  return (
    <section id="gray" className="h-full blue-zone bg-gray">
      <VizSensor
        onChange={setVisibility}
        active={!isVisible}
        partialVisibility={true}
      >
        <div className={baseClass}>
          {/* <div className="lg:col-start-3 lg:col-span-2 col-start-5 col-span-4 mt-12 lg:px-12">
            <Pic filename="home/ShieldB.png" />
          </div> */}
          <div className="col-span-12 p-10 lg:px-0 lg:col-start-3 lg:col-span-8">
            <h2 className="font-medium text-left text-gray-800 text-4xl lg:text-4xl">
              ¿Qué me cubre Alex?
            </h2>
            <h4 className="font-normal tracking-wide leading-normal text-lg">
              Alex es el único seguro de viaje que te cubre no solo cuando
              viajas, sino en cualquier momento de tu vida, si te encuentras
              viajando contarás con asistencia médica por emergencia (incluyendo
              Covid-19), emergencia dental, cancelación o retraso de vuelos,
              perdida o demora de equipaje, traslado y repatriación, pérdida de
              pasaporte y mucho más.
            </h4>
            <h4 className="font-normal tracking-wide leading-normal text-lg">
              Pero también estarás cubierto de cualquier accidente grave, que
              pueda sucederte en cualquier momento de tu vida, ya sea que el
              resultado derive en una invalidez y en dicho caso, cobrarás una
              indemnización, ó en el caso de fallecimiento, tus beneficiarios
              cobrarán la indemnización.
            </h4>
          </div>
        </div>
      </VizSensor>
    </section>
  )
}

export default GrayZone
