import React, { useState } from 'react'

// Components
import Plan from '../plans/plan'

// PDFs
import standardPdf from '../../data/standard.pdf'
import primePdf from '../../data/prime.pdf'
import fullPdf from '../../data/full.pdf'

// CSS
import './component.css'

const HomePlans = () => {
  const data = [
    {
      name: 'Standard',
      plan: 'standard',
      price: '11,50 ',
      features: [
        {
          type: 'Maximo beneficio',
          price: '25.000',
          active: true,
          symbol: '$',
          index: 0,
        },
        {
          type: 'Muerte accidental',
          price: 100,
          active: true,
          symbol: '%',
          index: 1,
        },
        {
          type: 'Invalidez total',
          price: 100,
          active: true,
          symbol: '%',
          index: 2,
        },
        {
          type: 'Invalidez parcial',
          price: 50,
          active: true,
          symbol: '%',
          index: 3,
        },
        {
          type: 'Desmembramiento',
          price: 100,
          active: true,
          symbol: '%',
          index: 4,
        },
        {
          type: 'Muerte natural',
          price: '5.000,00 ',
          active: true,
          symbol: '$',
          index: 5,
        },
        {
          type: 'Asistencia en viaje',
          price: '15.000,00 ',
          active: true,
          symbol: '$',
          index: 6,
        },
        {
          type: 'Asistencia funeraria',
          price: '2.500,00 ',
          active: true,
          symbol: '$',
          index: 7,
        },
      ],
    },
    {
      name: 'Prime',
      plan: 'plus',
      price: '22,50 ',
      features: [
        {
          type: 'Maximo beneficio',
          price: '50.000',
          active: true,
          symbol: '$',
          index: 0,
        },
        {
          type: 'Muerte accidental',
          price: 100,
          active: true,
          symbol: '%',
          index: 1,
        },
        {
          type: 'Invalidez total',
          price: 100,
          active: true,
          symbol: '%',
          index: 2,
        },
        {
          type: 'Invalidez parcial',
          price: 50,
          active: true,
          symbol: '%',
          index: 3,
        },
        {
          type: 'Desmembramiento',
          price: 100,
          active: true,
          symbol: '%',
          index: 4,
        },
        {
          type: 'Muerte natural',
          price: '10.000,00 ',
          active: true,
          symbol: '$',
          index: 5,
        },
        {
          type: 'Asistencia en viaje',
          price: '35.000,00 ',
          active: true,
          symbol: '$',
          index: 6,
        },
        {
          type: 'Asistencia funeraria',
          price: '3.500,00 ',
          active: true,
          symbol: '$',
          index: 7,
        },
      ],
    },
    {
      name: 'Full',
      plan: 'full',
      price: '36,50 ',
      features: [
        {
          type: 'Maximo beneficio',
          price: '100.000',
          active: true,
          symbol: '$',
          index: 0,
        },
        {
          type: 'Muerte accidental',
          price: 100,
          active: true,
          symbol: '%',
          index: 1,
        },
        {
          type: 'Invalidez total',
          price: 100,
          active: true,
          symbol: '%',
          index: 2,
        },
        {
          type: 'Invalidez parcial',
          price: 50,
          active: true,
          symbol: '%',
          index: 3,
        },
        {
          type: 'Desmembramiento',
          price: 100,
          active: true,
          symbol: '%',
          index: 4,
        },
        {
          type: 'Muerte natural',
          price: '20.000,00 ',
          active: true,
          symbol: '$',
          index: 5,
        },
        {
          type: 'Asistencia en viaje',
          price: '50.000,00 ',
          active: true,
          symbol: '$',
          index: 6,
        },
        {
          type: 'Asistencia funeraria',
          price: '4.500,00 ',
          active: true,
          symbol: '$',
          index: 7,
        },
      ],
    },
  ]
  const getPdf = name => {
    switch (name) {
      case 'Standard':
        return standardPdf
      case 'Prime':
        return primePdf
      case 'Full':
        return fullPdf
      default:
        break
    }
  }
  const [selected, setPlanSelected] = useState(data[1])
  return (
    <>
      <div className="grid grid-cols-12 mt-16 mb-10">
        <div className="col-start-2 lg:col-start-3 col-span-10">
          <h1 className="text-4xl font-medium text-gray-800 mb-10">
            Elige tu plan:
          </h1>
        </div>
        <div className="col-start-2 lg:col-start-3 col-span-10">
          <div className="grid grid-cols-12 gap-2">
            {data &&
              data.map((pl, i) => {
                return (
                  <div
                    key={i}
                    className="col-span-12 mb-10 lg:mb-0 lg:col-span-3 tracking-tight"
                  >
                    <Plan
                      onSelect={setPlanSelected}
                      cardSelected={selected}
                      plan={pl}
                      pdf={getPdf(pl.name)}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePlans
