import React, { useState } from 'react'
import { navigate } from 'gatsby'
import Video from '../video'

// Libs
import VizSensor from 'react-visibility-sensor'
import classnames from 'classnames'

//Components
import Pic from '../images/pic'

const Component = () => {
  const [isVisible, setVisibility] = useState(false)
  let baseClass = classnames(
    { 'opacity-0': !isVisible },
    { 'text-slide-in': isVisible }
  )

  return (
    <section className="grid grid-cols-12 my-20">
      <VizSensor
        onChange={setVisibility}
        active={!isVisible}
        partialVisibility={true}
      >
        <>
          <div className="col-span-12 col-start-2 lg:col-start-3 lg:col-span-4 pr-10 lg:mt-12">
            <div className={baseClass}>
              <h2 className="font-medium text-gray-800 text-4xl">
                ¿Qué es Alex?
              </h2>
              <h3 className="text-lg font-normal tracking-wide leading-tight">
                Alex es mucho más que un seguro de viaje, ya que incluye todas
                las coberturas típicas de asistencia en viaje, pero además
                incluye un seguro de accidentes, un seguro de vida básico,
                asistencia funeral y repatriación.
              </h3>
              <h3 className="text-lg font-normal tracking-wide leading-tight">
                Con Alex no tienes que preocuparte en buscar seguro de viajes
                cada vez que viajas, ya que nuestras pólizas son de duración
                anual, así que cualquier viaje que hagas dentro de tu periodo de
                cobertura, estarás cubierto, siempre y cuando, el viaje no
                supere los 60 días continuos.
              </h3>
              <p className="text-grayText text-xs font-light">
                Los productos Alex no son ofrecidos en EE.UU.
              </p>
              <button
                onClick={() => {
                  navigate('quote')
                }}
                className="default-button font-bold py-4 px-6 "
              >
                Cotizar desde $11,50/mes
              </button>
            </div>
          </div>
          <div className="mt-6 xl:py-12 lg:col-start-7 lg:col-span-4 pt-20 col-span-12">
            <Pic filename="home/plane.png" />
          </div>
        </>
      </VizSensor>
    </section>
  )
}

export default Component
