import React from 'react'
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <section className="grid grid-cols-12">
    <div className="xl:col-span-8 xl:col-start-3 xl:py-20 pt-20 col-span-12">
      <div className="video">
        <iframe
          {...props}
          className="video-iframe rounded w-11/12 mx-auto"
          src={videoSrcURL}
          title={videoTitle}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
    </div>
  </section>
)
export default Video
