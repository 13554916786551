import React, { useState } from 'react'
import VizSensor from 'react-visibility-sensor'
import classnames from 'classnames'

//Components
import Pic from '../images/pic'

const ReferralsZone = () => {
  const [isVisible, setVisibility] = useState(false)
  let baseClass = classnames(
    'grid',
    'grid-cols-12',
    { 'opacity-0': !isVisible },
    { 'text-slide-in': isVisible }
  )

  return (
    <section id="blue" className="blue-zone mt-2 lg:mt-20 mb-12">
      <VizSensor
        onChange={setVisibility}
        active={!isVisible}
        partialVisibility={true}
      >
        <div className={baseClass}>
          <div className="hidden md:block col-span-12 md:col-span-5 lg:col-start-3 lg:col-span-4">
            <Pic filename="Referrals.png" />
          </div>
          <div className="col-start-2 col-span-10 md:col-start-7 lg:col-start-7 lg:col-span-4 mt-8">
            <h2 className="font-medium text-gray-800 text-4xl">
              Invita a tus amigos que se unan a Alex
            </h2>
            <h4 className="font-normal tracking-wide leading-relaxed text-xl">
              Con Alex recibes{' '}
              <span className="text-xl font-bold text-gray-800">$30</span> por
              cada amigo que invites, además actualmente todos los planes Alex
              tienen un{' '}
              <span className="text-xl font-bold text-gray-800">10%</span> de
              descuento ya aplicado
            </h4>
            <div className="col-start-2">
              <h4 className="font-normal tracking-wide leading-normal text-sm lg:mb-0 lg:text-sm">
                Para poder optar por esta promoción, ambas personas deben
                adquirir un plan Alex.
              </h4>
              <p className="text-sm my-1 italic">Promoción limitada.</p>
              <p className="text-sm italic">Ciertas condiciones aplican.</p>
            </div>
            {/* <button className="default-button px-8 mb-20 font-bold rounded p-3">
              Invitar amigos
            </button> */}
          </div>
        </div>
      </VizSensor>
    </section>
  )
}

export default ReferralsZone
